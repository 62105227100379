import React from 'react';
import { Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ItemSchemaGroupForm } from './Form';

type CreateItemSchemaGroupButtonProps = {
  restaurantID: string;
  onSuccess?: () => void;
}

export const CreateItemSchemaGroupButton = (props: CreateItemSchemaGroupButtonProps) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleSuccess = () => {
    setIsModalVisible(false);
    if (props.onSuccess) {
      props.onSuccess();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button 
        type="primary" 
        icon={<PlusOutlined />}
        onClick={showModal}
      >
        New Item Group / 新增菜單品類
      </Button>
      <Modal
        title="Create New Item Schema Group / 新增菜單品類"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <ItemSchemaGroupForm
          restaurantID={props.restaurantID}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
        />
      </Modal>
    </>
  );
}; 