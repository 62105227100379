import React from 'react';

import {
  Button,
  Form,
  Input,
  InputNumber,
  notification,
  Select,
} from "antd";

import { API_URL } from "../Util";
import { fetchT } from "../TypedUtil";

type CreateItemSchemaGroupRequest = {
  item_schema_group: {
    name: string;
    menu_type: string;
    seq: number;
  };
}

type CreateItemSchemaGroupResponse = {
  id: string;
}

type ItemSchemaGroupFormProps = {
  restaurantID: string;
  onSuccess: () => void;
  onCancel: () => void;
}

export const ItemSchemaGroupForm = (props: ItemSchemaGroupFormProps) => {
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [form] = Form.useForm();

  const handleSave = (values: any) => {
    setSaveLoading(true);

    const req: CreateItemSchemaGroupRequest = {
      item_schema_group: {
        name: values.name,
        menu_type: values.menu_type,
        seq: values.seq,
      }
    };

    fetchT<CreateItemSchemaGroupResponse>(
      "POST",
      `${API_URL}/v1/r/${props.restaurantID}/item_schema_groups`,
      req,
      (result: CreateItemSchemaGroupResponse) => {
        notification['success']({
          message: 'Success',
          description: `Created new item schema group successfully!`,
        });
        form.resetFields();
        setSaveLoading(false);
        props.onSuccess();
      },
      (err: any) => {
        setSaveLoading(false);

        let description: string = "";
        try {
          description = JSON.parse(err)["message"];
        } catch(e) {
          description = "An error occurred";
        }

        notification['error']({
          message: 'Error',
          description: description,
        });
      },
    );
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      labelAlign="left"
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      onFinish={handleSave}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please input the group name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Menu Type"
        name="menu_type"
        rules={[{ required: true, message: 'Please select the menu type' }]}
      >
        <Select>
          <Select.Option value="dine_in">Dine In / Pickup</Select.Option>
          <Select.Option value="large_volume">Group Order</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Sequence"
        name="seq"
        rules={[{ required: true, message: 'Please input the sequence number' }]}
        initialValue={0}
      >
        <InputNumber min={0} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button 
          type="primary" 
          htmlType="submit" 
          loading={saveLoading}
          style={{ marginRight: '8px' }}
        >
          Create
        </Button>
        <Button onClick={props.onCancel}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
}; 